import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import logo from "@/images/v-logo.png";

const TopInfo = () => {
  return (
    <section className="commonSection business-section">
      <Container>
        <Row className="mb-2 justify-content-center">
          <h1 className="text-uppercase text-center">OFERTY SPONSORINGOWE</h1>
          <p className="w-75 text-center mt-4">
            Non quis eiusmod irure pariatur magna ex ad ex minim eiusmod tempor
            cillum. Nostrud ea magna ut ipsum tempor enim laborum dolor nulla
            magna ad dolore eiusmod. Consectetur ullamco ut et non laborum
            magna. Ea adipisicing amet exercitation quis Lorem ullamco. Veniam
            commodo veniam aliquip et duis qui consequat cupidatat mollit.
            Cupidatat dolor esse laboris et amet pariatur sunt.
          </p>
        </Row>
        <Row className="my-5 justify-content-center align-items-center w-75 mx-auto">
          <Col lg={3} md={6} sm={6} className="col-12">
            <Link to="#">
              <div className="singleTM card-container squad-card-container text-end">
                <div
                  className="tm_img squad-showcase business-card-img"
                  style={{ backgroundImage: `url('${logo}')` }}
                >
                  <div className="tm_overlay"></div>
                  <div className="squad-showcase-number">
                    Oferta <div className="business-offer-name">Jaga</div>
                  </div>
                  <div className="detail_TM text-center">
                    <h5>Pobierz</h5>
                    <h5>ofertę</h5>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={3} md={6} sm={6} className="col-12">
            <Link to="#">
              <div className="singleTM card-container squad-card-container text-end">
                <div
                  className="tm_img squad-showcase business-card-img"
                  style={{ backgroundImage: `url('${logo}')` }}
                >
                  <div className="tm_overlay"></div>
                  <div className="squad-showcase-number">
                    Oferta <div className="business-offer-name">Jaga</div>
                  </div>
                  <div className="detail_TM text-center">
                    <h5>Pobierz</h5>
                    <h5>ofertę</h5>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={3} md={6} sm={6} className="col-12">
            <Link to="#">
              <div className="singleTM card-container squad-card-container text-end">
                <div
                  className="tm_img squad-showcase business-card-img"
                  style={{ backgroundImage: `url('${logo}')` }}
                >
                  <div className="tm_overlay"></div>
                  <div className="squad-showcase-number">
                    Oferta <div className="business-offer-name">Jaga</div>
                  </div>
                  <div className="detail_TM text-center">
                    <h5>Pobierz</h5>
                    <h5>ofertę</h5>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={3} md={6} sm={6} className="col-12">
            <Link to="#">
              <div className="singleTM card-container squad-card-container text-end">
                <div
                  className="tm_img squad-showcase business-card-img"
                  style={{ backgroundImage: `url('${logo}')` }}
                >
                  <div className="tm_overlay"></div>
                  <div className="squad-showcase-number">
                    Oferta <div className="business-offer-name">Jaga</div>
                  </div>
                  <div className="detail_TM text-center">
                    <h5>Pobierz</h5>
                    <h5>ofertę</h5>
                  </div>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TopInfo;
